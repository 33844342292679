import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createNote, deleteOneNote, editNote, getAllNotes, getOneNote } from './actions';
var initialState = {
    notes: { docs: [], meta: {} },
    note: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'notes',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // POST
        builder
            .addCase(createNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createNote.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.note = action.payload.data;
        })
            .addCase(createNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // Edit
        builder
            .addCase(editNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(editNote.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.note = action.payload.data;
        })
            .addCase(editNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneNote.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.note = action.payload.data;
        })
            .addCase(getOneNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getAllNotes.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllNotes.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.notes = action.payload;
        })
            .addCase(getAllNotes.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteOneNote.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOneNote.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.notes.docs = state.notes.docs.filter(function (note) { return note._id !== action.meta.arg.noteId; });
        })
            .addCase(deleteOneNote.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
